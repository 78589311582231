@tailwind base;
@tailwind components;
@tailwind utilities;
/* Hide scrollbar in WebKit-based browsers */
body::-webkit-scrollbar {
  width: 0.5em;
  display: none; /* Hide the scrollbar */
}

/* Track */
body::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 0.25em;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 142.1 76.2% 36.3%;
    --primary-foreground: 355.7 100% 97.3%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;

    --header-height: 64px;
    --quick-nav-display: none;
    --component-highlights-item-width: calc(100vw - 100px);

    --b-primary: 221.2 83.2% 53.3%;
    --b-primary-foreground: 210 40% 98%;

    --b-warn: 47.9 95.8% 53.1%;
    --b-warn-foreground: 26 83.3% 14.1%;

    --b-success: 142.1 76.2% 36.3%;
    --b-success-foreground: 355.7 100% 97.3%;

    --counter: 221.2 83.2% 53.3%;
    --counter-foreground: 210 40% 98%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;

    --b-primary: 217.2 91.2% 59.8%;
    --b-primary-foreground: 222.2 47.4% 11.2%;

    --b-warn: 0 72.2% 50.6%;
    --b-warn-foreground: 0 85.7% 97.3%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;
  }
}


@media (min-width: 768px) {
  :root {
    --component-highlights-item-width: 325px;
  }
}

@media (min-width: 1280px) {
  :root {
    --component-highlights-image-height: 850px;
  }
}

@media (min-width: 1440px) {
  :root {
    --quick-nav-display: block;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }
}


/* input verification */

.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;

  /* light theme */
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;

}

.character--selected {
  position: relative;
}

.character--selected:empty::after {
  content: "";
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: blink 1s infinite step-end;
}

.no-border td {
  border: 0;
}

@keyframes pulse-border {
  0%, 100% {
    border-color: #fff; /* Replace with your desired color */
  }
  50% {
    border-color: #888; /* Replace with your desired color */
  }
}
